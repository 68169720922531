<template>
  <div class="container-xxl py-2">
    <div v-if="isLoading">
      <div class="text-center my-4">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </div>
    <div v-else>
      <div class="mb-3">
        <router-link
          class="font-weight-bold"
          :to="{ name: 'editAccount', params: { uuid: accountUuid } }"
        >
          <b-icon scale="1.25" icon="arrow-left" class="mr-1 icon-hover" />
          Account
        </router-link>
      </div>
      <b-row>
        <b-col :lg="12" class="d-flex align-items-end">
          <span v-if="canManageSubscriptionActivation" class="d-flex mr-3 mb-1">
            <b-form-checkbox
              v-if="isSubscriptionPresent"
              @change="showSubscriptionActivationModal()"
              v-model="isSwitchActive"
              name="check-button"
              switch
              size="lg"
              class="switch"
            >
              <span v-if="isActive">Active</span>
              <span v-else>Inactive</span>
            </b-form-checkbox>

            <activate-modal
              resource-name="subscription"
              :uuid="uuid"
              v-on:activated="toggleSubscriptionActivation"
              v-on:canceled="cancelSubscriptionActivation"
            />

            <deactivate-modal
              resource-name="subscription"
              :uuid="uuid"
              v-on:deactivated="setSubscriptionDeactivationReason"
              v-on:canceled="cancelSubscriptionActivation"
            />
          </span>
          <span v-else>
            <h4 class="mr-3">
              <b-badge v-if="isActive" variant="soft-success">Active</b-badge>
              <b-badge v-else variant="soft-danger">Inactive</b-badge>
            </h4>
          </span>

          <span v-if="logoUrl" class="solana-logo mr-3 mb-1">
            <img
              class="rounded"
              :src="logoUrl"
              height="25"
            />
          </span>

          <span class="h5 font-monospace text-secondary mb-2">
            <template v-if="currentUser.is_admin">
              Type
              <b-link v-b-modal="'modal-' + subscriptionType.uuid">
                {{ subscriptionTypeHeader }}
              </b-link>
              <subscription-type-modal :uuid="subscriptionType.uuid" />
            </template>
            <template v-else>
              Type: {{ subscriptionTypeHeader }}
            </template>
          </span>

          <span
            v-if="subscription.cascade_marketplace"
            class="ml-3 h5 text-secondary border mb-1"
          >
            <b-badge variant="secondary" pill>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plane w-[40px] h-[40px] stroke-triton-fgd-1"><path d="M17.8 19.2 16 11l3.5-3.5C21 6 21.5 4 21 3c-1-.5-3 0-4.5 1.5L13 8 4.8 6.2c-.5-.1-.9.1-1.1.5l-.3.5c-.2.5-.1 1 .3 1.3L9 12l-2 3H4l-1 1 3 2 2 3 1-1v-3l3-2 3.5 5.3c.3.4.8.5 1.3.3l.5-.2c.4-.3.6-.7.5-1.2z"></path></svg>

              Cascade Marketplace
            </b-badge>
          </span>
        </b-col>

        <b-col :lg="12" class="d-flex align-items-end justify-content-end">
          <b-form-checkbox
            @change="setHideDisabledEndpointsStatus"
            v-model="hideDisabledEndpoints"
            name="check-button"
            switch size="md"
            class="hide-endpoints-button mb-1"
          >
            Hide disabled endpoints
          </b-form-checkbox>

          <span v-if="currentUser.is_admin" class="h6 ml-2">
            <b-link v-b-modal="'versions-modal-' + subscription.uuid">
              <span>Versions</span>
            </b-link>

            <versions-modal
              :title="'Subscription ' + subscription.name"
              :uuid="subscription.uuid"
              :versions="subscription.versions"
            />
          </span>

          <span v-if="isMetricsActive" class="h6 ml-2">
            <router-link :to="metricsUrl">
              Metrics
            </router-link>
          </span>

          <span v-if="canManageSubscriptionActivation && hasDeactivationReasons" class="h6 ml-2">
            <b-link v-b-modal="'deactivation-reasons-modal-' + uuid">
              Deactivation reasons
            </b-link>

            <deactivation-reasons-modal
              title="Subscription"
              :uuid="uuid"
              :deactivationReasons="subscription.deactivation_reasons"
            />
          </span>

          <span class="h6 ml-2">
            Belongs to:
            <router-link
              v-if="accountUuid"
              :to="{ name: 'editAccount', params: { uuid: accountUuid } }"
            >
              {{ subscription.account.name }}
            </router-link>
          </span>
        </b-col>
      </b-row>

      <update-subscription-form
        v-if="subscription.uuid && account.uuid"
        :subscription="subscription"
        :account="account"
      />

      <div class="row">
        <div class="col-lg-6">
          <div class="card pr-4 mb-3">
            <div class="card-header">
              <h2>RPC Tokens</h2>
              <p v-if="!canUpdateTokens" class="fs--1 mb-0">
                Can't be edited
              </p>
            </div>
            <div class="card-body pr-0">
              <tokens-table
                :tokens="tokens"
                :endpoints="endpoints"
                :subscription="subscription"
                :supported-rate-tiers="subscription.supported_rate_tiers"
                @token-state-changed="toggleTokenState"
                :isAccountAndSubscriptionActive="isAccountAndSubscriptionActive"
              />

              <div v-if="canCreateTokens && subscription.supported_rate_tiers.length">
                <h4 class="mt-4">Create new token</h4>
                <token-form
                  v-on:add-token="addToken"
                  :supportedRateTiers="subscription.supported_rate_tiers"
                />
              </div>
            </div>
          </div>

          <div v-if="canUseAddressWatchLists">
            <div class="card pr-4 mb-3">
              <div class="card-header">
                <h2>Address Watch Lists</h2>
              </div>
              <div class="card-body pr-0">
                <address-watch-lists-table :subscription="subscription" />
              </div>
            </div>
          </div>

          <div v-if="currentUser.is_admin || subscription.cascade_marketplace" class="mb-3">
            <subscription-features-card :subscription="subscription" />
          </div>
        </div>

        <div v-if="canUseEndpoints" class="col-lg-6">
          <div v-if="endpoints.length > 0 || currentUser.is_admin">
            <div v-for="endpoint in endpointsToShow" :key="endpoint.uuid" class="card mb-3">
              <endpoint-update-form
                v-if="endpoint.uuid"
                :endpoint="endpoint"
                :subscription="subscription"
                :supportedRateTiers="subscription.supported_rate_tiers"
                :isAccountAndSubscriptionActive="isAccountAndSubscriptionActive"
                v-on:endpoint-deleted="removeEndpoint(endpoint)"
                v-on:endpoint-activation-toggled="toggleEndpointActivation"
              />
            </div>
          </div>

          <div v-if="canCreateEndpoints" class="card p-3 mb-3">
            <h4>Create new endpoint</h4>
            <endpoint-form
              :subscriptionUuid="this.$route.params.uuid"
              :supportedRateTiers="subscription.supported_rate_tiers"
              v-on:add-endpoint="addEndpoint"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';
import { mapGetters } from 'vuex';

// components
import tokensTable from './tokens-table';
import updateSubscriptionForm from './update-subscription-form';
import versionsModal from '../../globals/versions-modal';
import addressWatchListsTable from './address-watch-lists-table';
import subscriptionTypeModal from '@/components/globals/subscription-type-modal.vue';
import subscriptionFeaturesCard from './subscription-features-card';

export default {
  name: 'EditSubscriptionView',

  components: {
    tokensTable,
    updateSubscriptionForm,
    versionsModal,
    addressWatchListsTable,
    subscriptionTypeModal,
    subscriptionFeaturesCard,
  },

  data() {
    return {
      isLoading: true,
      isSwitchActive: false,
      subscription: {},
      subscriptionType: null,
      name: null,
      uuid: null,
      isActive: null,
      accountUuid: null,
      tokens: [],
      endpoints: [],
      hideDisabledEndpoints: false,
    };
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser',
      'canUpdateTokens',
      'canCreateTokens',
      'canCreateEndpoints',
      'canManageWatchLists',
      'canUpdateSubscriptions',
      'canManageSubscriptionActivation'
    ]),

    endpointsToShow() {
      return this.hideDisabledEndpoints ? this.endpoints.filter(e => e.is_active) : this.endpoints;
    },

    account() {
      return this.subscription?.account;
    },

    canUseAddressWatchLists() {
      return this.account?.address_watch_lists && this.canManageWatchLists;
    },

    canUseEndpoints() {
      return this.canCreateEndpoints || this.endpoints.length > 0 || this.currentUser?.is_admin
    },

    paymentMethodIsHelio() {
      return this.account.payment_method === 'helio';
    },

    logoUrl() {
      return this.subscriptionType?.logo_url;
    },

    subscriptionTypeHeader() {
      return this.subscriptionType?.display_name || this.subscriptionType?.name;
    },

    isAccountAndSubscriptionActive() {
      return this.account && this.account.is_active && this.isActive;
    },

    isMetricsActive() {
      if (this.currentUser?.is_admin) {
        return true;
      } else {
        return this.account.metrics_enabled &&
          this.isAccountAndSubscriptionActive &&
          (this.hasActiveEndpoints || this.hasActiveTokens);
      }
    },

    hasActiveEndpoints() {
      return this.endpoints.some(endpoint => endpoint.is_active);
    },

    hasActiveTokens() {
      return this.tokens.some(token => token.is_active);
    },

    isSubscriptionPresent() {
      return !!this.subscription?.uuid
    },

    metricsUrl() {
      return {
        path: `/accounts/${this.accountUuid}/metrics`,
        query: {
          selected_subscription_uuid: this.uuid
        }
      };
    },

    hasDeactivationReasons() {
      return this.subscription.deactivation_reasons?.length;
    },
  },

  methods: {
    async onCreated() {
      this.getHideDisabledEndpointsStatus();

      try {
        await this.fetchSubscription(this.$route.params.uuid);
        await this.fetchAccount();
        await this.fetchTokens();
        await this.fetchEndpoints();
      }

      catch (error) {
        console.log(error);
      }
    },

    addToken(token) {
      this.tokens.push(token);
    },

    addEndpoint(endpoint) {
      this.endpoints.push(endpoint);
    },

    removeEndpoint(endpoint) {
      const uuids = this.endpoints.map(e => e.uuid);
      const i = uuids.indexOf(endpoint.uuid);
      this.endpoints.splice(i, 1);
    },

    toggleEndpointActivation(endpoint, status) {
      const uuids = this.endpoints.map(e => e.uuid);
      const i = uuids.indexOf(endpoint.uuid);
      this.endpoints[i].is_active = status;
    },

    toggleTokenState(token) {
      token.is_active = !token.is_active;
    },

    async fetchSubscription(uuid) {
      try {
        const response = await http.get(`subscriptions/${uuid}`);
        const subscription = response.data.subscription;
        this.subscription = subscription;
        this.subscriptionType = subscription.subscription_type;
        this.accountUuid = subscription.account_uuid;
        this.uuid = subscription.uuid;
        this.name = subscription.name;
        this.isSwitchActive = this.isActive = subscription.is_active;
        // this.startsAt = subscription.starts_at;
        // this.endsAt = subscription.ends_at;
      }

      catch (error) {
        console.log(error);

        if (error.status === 403 || error.status === 404) {
          this.$router.push({
            name: 'notFound',
            params: { message: 'Subscription with given UUID cannot be found.' }
          });
        }
      }

      finally {
        this.isLoading = false;
      }
    },

    async fetchAccount() {
      const response = await http.get(`accounts/${this.accountUuid}`);
      const account = response.data.account;
      this.$store.commit('accounts/SET_CURRENT_ACCOUNT', account);
    },

    async fetchTokens() {
      const response = await http.get(`tokens?subscription_uuid=${this.uuid}`);
      this.tokens = response.data.tokens;
    },

    async fetchEndpoints() {
      const response = await http.get(`endpoints?subscription_uuid=${this.uuid}`);
      this.endpoints = response.data.endpoints;
    },

    showSubscriptionActivationModal() {
      if (this.isActive) {
        this.$bvModal.show(`deactivate-modal-${this.uuid}`);
      } else {
        this.$bvModal.show(`activate-modal-${this.uuid}`);
      }
    },

    toggleSubscriptionActivation() {
      this.isSwitchActive = this.isActive = !this.isActive;
    },

    cancelSubscriptionActivation() {
      this.isSwitchActive = this.isActive;
    },

    setSubscriptionDeactivationReason(reason) {
      this.subscription.deactivation_reasons.push(reason);

      this.toggleSubscriptionActivation();
    },

    toggleEndpointsDisableStatus(hideDisabled) {
      this.hideDisabledEndpoints = hideDisabled;
    },

    setHideDisabledEndpointsStatus() {
      localStorage.setItem("hideDisableEndpoints", this.hideDisabledEndpoints);
    },

    getHideDisabledEndpointsStatus() {
      const savedStatus = localStorage.getItem("hideDisableEndpoints");

      if (savedStatus) {
        this.hideDisabledEndpoints = savedStatus === 'true';
      }
    },
  },
}
</script>

<style scoped>
.solana-logo {
  position: relative;
  top: -5px;
}

.switch {
  margin-bottom: 1px;
}

.hide-endpoints-button {
  top: 1px;
}
</style>